export default {
  footer: {
    telegram: "",
    aboutDfuse: "",
    copyright: "© 2018-{{year}} Ultra. All Rights Reserved.",
    privacyPolicy: "",
    privacyPolicyLink: "",
    termsOfServices: "Terms of Service",
    termsOfServicesLink: "https://ultra.io/terms"
  }
}
