export const colors = {
  primary: "#ffffff",
  secondary: "#fff",
  ternary: "#312d36",
  green5: "#1ccab0",
  secondHighlight: "#FFB866",
  logo: "#322E37",
  logo1: "#fff",
  logo2: "#7e90fb",
  highlight: "#322E37",
  highlight2: "#7A85FF",
  border: "#ffffff",
  neutral: "#aaaeb3",
  grey1: "#f2f5f9",
  grey2: "#eaeff5",
  grey3: "#dce1e8",
  grey4: "#d3d7dd",
  grey5: "#aaaeb3",
  grey6: "#8d939a",
  grey7: "#71727D",
  text: "#65656f",
  header: "#25262B",
  link: "#7A85FF",
  link2: "#7A85FF",
  link3: "#7A85FF",
  bleu11: "#302B35",
  bleu10: "#302B35",
  bleu9: "#fff",
  bleu8: "#835DD4",
  bleu7: "#322E37",
  bleu6: "#fff",
  bleu5: "#fff",
  bleu4: "#fff",
  verified: "darkgreen",
  notverified: "#aaaeb3",
  menuActive: "#fff",
  permissionBg: "#f5f8fc",
  permissionBg2: "#c2cbff",
  // FIXME: Link to ternary instead of copying somehow
  linkHover: "#27cfb7",
  formSelectorBg: "#f5f8fc",
  fieldIcon: "#8e8f9d",
  formSelectorBorder: "#d3d7dd",
  formFieldBg: "#ebeef2",
  // FIXME: Link to border & header respectively
  banner: "#322E37",
  bannerHover: "#322E37",
  bannerTitle: "#fff",
  bannerValue: "#fff",
  bannerSubtitle: "#c2cbff",
  panelBackground: "#fff",
  panelGrayBackground: "#4A4A5E",
  panelBackgroundDisabled: "#f8f9fa",

  pillTitleText: "#616082",
  tableEvenRowBackground: "#f8f9fa",
  tableOddRowBackground: "#fff",

  trendDown: "#ec1c24",
  trendUp: "#7bda49",
  transactionProgress: "#484599",
  traceAccountText: "#fff",
  traceAccountTokenBackground: "#322E37",
  traceAccountSystemBackground: "#072242",
  traceAccountGenericBackground: "#8d939a",
  traceActionGenericText: "#65656f",
  traceActionReceiveBackground: "#90ded8",
  traceActionSendBackground: "#ffc88a",
  traceActionGenericBackground: "#eaeff5",
  traceDataHoverBackground: "#EAEEF2",
  traceMemoBackground: "#d3d7dd",
  traceMemoText: "#58596c",

  traceRawButtonBackground: "#EAEEF2",
  traceRawButtonText: "#5F6070",

  toolTipDark: "#343442",
  toolTipLightBg: "#F5F8FC",
  toolTipLightBorder: "#71727D",

  stakeCPU: "#322E37",
  stakeREX: "#e50fb6",
  stakeREXFunds: "#f976db",
  delegateCPU: "#686EDE",
  stakeNetwork: "#AE62D4",
  delegateNetwork: "#9D47C7",
  barDataValue: "#4f4fa6",
  barBackground: "#dce1e8",
  barVote: "#4f4fa6",
  barVoteBg: "#dce1e8",

  accountVoteBtn: "#EAEEF2",
  accountVoteBtnOver: "#dee1e4",
  accountVoteBtnOverLink: "#5451b3",
  topBarBackground: "#363670",

  searchBg: "#4f4fa6",
  searchBorder: "#6565d1",
  searchPlaceholder: "#322E37",

  statusBadgeBan: "#EE4036",
  statusBadgeBanBg: "#FDE4E3",
  statusBadgeClock: "#FF971F",
  statusBadgeClockBg: "#FFEDD7",
  statusBadgeCheck: "#24C1AA",
  statusBadgeCheckBg: "#F2F5F9",

  testnet: "#f05a28",

  editAdd: "#25c2ab",
  editRemove: "#EE4036"
}
